import { useState} from "react";
import { problemDictionary } from "../utils/problemDictionary";

export default function CheckBoxes({ selectedProblemTypes, setSelectedProblemTypes }) {
    const [checkedItems, setCheckedItems] = useState([]);

    const handleChange = (event) => {
        const { value, checked } = event.target;
        let updatedCheckedItems;
        if (checked) {
            updatedCheckedItems = [...checkedItems, value];
        } else {
            updatedCheckedItems = checkedItems.filter(item => item !== value);
        }
        setCheckedItems(updatedCheckedItems);
        setSelectedProblemTypes(updatedCheckedItems);
    };

    return <div className="d-flex">
        {Object.keys(problemDictionary).map((key) => {
            return <div key={key} className="mx-2">
                    <input type="checkbox" value={key} id={key} onChange={handleChange} />
                    <label htmlFor={key}>{problemDictionary[key]['kor_type_name']}</label>
            </div>
        })}
    </div>
}