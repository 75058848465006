import API_BASE_URL from "../utils/API_BASE_URL"
import axios from "axios"

export function SaveButtonForBinkan({paragraph_content, answer_str, answer_str_writer, options})
{
    const postProblem = async (problemToSave) => {
        const response = await axios.post(API_BASE_URL, {
            jwt : sessionStorage.getItem("jwt"),
            action : "save_problem",
            data : problemToSave
        });
        console.log(response)
        alert("저장되었습니다!")
    }
    const saveBinkanProblem = () => {
        let problemToSave = {}
        problemToSave = {
            "problem_type" : "binkan",
            "paragraph" : {
                "content" : paragraph_content,
                "translation" : null
            },
            "footnote": null,
            "answer_str" : answer_str,
            "answer_str_writer" : answer_str_writer,
            "options" : options,
            "explanation" : null,
        }
        console.log("PROBLEM_TO_SAVE :", problemToSave)
        postProblem(problemToSave)
    }
    return <div>
        <button onClick={saveBinkanProblem} className="border rounded">
            저장하기
        </button>
    </div>
}

export function SaveButtonForHamui({paragraph_content, given_text, given_text_writer, options})
{
    const postProblem = async (problemToSave) => {
        const response = await axios.post(API_BASE_URL, {
            jwt : sessionStorage.getItem("jwt"),
            action : "save_problem",
            data : problemToSave
        });
        console.log(response)
        alert("저장되었습니다!")
    }
    const saveHamuiProblem = () => {
        let problemToSave = {}
        problemToSave = {
            "problem_type" : "hamui",
            "paragraph" : {
                "content" : paragraph_content,
                "translation" : null
            },
            "footnote": null,
            "given_text" : given_text,
            "given_text_writer" : given_text_writer,
            "options" : options,
            "explanation" : null,
        }
        console.log("PROBLEM_TO_SAVE :", problemToSave)
        postProblem(problemToSave)
    }
    return <div>
        <button onClick={saveHamuiProblem} className="border rounded">
            저장하기
        </button>
    </div>
}

export function SaveButtonForSabib({paragraph_content, given_text, given_text_writer, answer_str})
{
    const postProblem = async (problemToSave) => {
        const response = await axios.post(API_BASE_URL, {
            jwt : sessionStorage.getItem("jwt"),
            action : "save_problem",
            data : problemToSave
        });
        console.log(response)
        alert("저장되었습니다!")
    }
    const saveSabibProblem = () => {
        let problemToSave = {}
        problemToSave = {
            "problem_type" : "sabib",
            "paragraph" : {
                "content" : paragraph_content,
                "translation" : null
            },
            "footnote": null,
            "given_text" : given_text,
            "given_text_writer" : given_text_writer,
            "answer_str" : answer_str,            
            "explanation" : null,
        }
        console.log("PROBLEM_TO_SAVE :", problemToSave)
        postProblem(problemToSave)
    }
    return <div>
        <button onClick={saveSabibProblem} className="border rounded">
            저장하기
        </button>
    </div>
}

export function SaveButtonForSunseo({paragraphs, given_text, given_text_writer, answer_str})
{
    const postProblem = async (problemToSave) => {
        const response = await axios.post(API_BASE_URL, {
            jwt : sessionStorage.getItem("jwt"),
            action : "save_problem",
            data : problemToSave
        });
        console.log(response)
        alert("저장되었습니다!")
    }
    const saveSunseoProblem = () => {
        let problemToSave = {}
        problemToSave = {
            "problem_type" : "sunseo",
            "paragraphs" : paragraphs,
            "footnote": null,
            "given_text" : given_text,
            "given_text_writer" : given_text_writer,
            "answer_str" : answer_str,
            "explanation" : null,
        }
        console.log("PROBLEM_TO_SAVE :", problemToSave)
        postProblem(problemToSave)
    }
    return <div>
        <button onClick={saveSunseoProblem}>저장하기</button>
    </div>
}

export function SaveButtonForDaeui({problem_type, paragraph_content, options})
{
    const postProblem = async (problemToSave) => {
        const response = await axios.post(API_BASE_URL, {
            jwt : sessionStorage.getItem("jwt"),
            action : "save_problem",
            data : problemToSave
        });
        console.log(response)
        alert("저장되었습니다!")
    }
    const saveDaeuiProblem = () => {
        let problemToSave = {}
        problemToSave = {
            "problem_type" : problem_type,
            "paragraph" : {
                "content" : paragraph_content,
                "translation" : null
            },
            "footnote": null,
            "options" : options,
            "explanation" : null,
        }
        console.log("PROBLEM_TO_SAVE :", problemToSave)
        postProblem(problemToSave)
    }
    return <div>
        <button onClick={saveDaeuiProblem} className="border rounded">
            저장하기
        </button>
    </div>
}

export function SaveButtonForUbub({paragraph_content, grammar, answer_str, options})
{
    const postProblem = async (problemToSave) => {
        const response = await axios.post(API_BASE_URL, {
            jwt : sessionStorage.getItem("jwt"),
            action : "save_problem",
            data : problemToSave
        });
        console.log(response)
        alert("저장되었습니다!")
    }
    const saveUbubProblem = () => {
        let problemToSave = {}
        problemToSave = {
            "problem_type" : "ubub",
            "paragraph" : {
                "content" : paragraph_content,
                "translation" : null
            },
            "grammar" : grammar,
            "footnote": null,
            "answer_str" : answer_str,            
            "options" : options,
            "explanation" : null,
        }
        console.log("PROBLEM_TO_SAVE :", problemToSave)
        postProblem(problemToSave)
    }
    return <div>
        <button onClick={saveUbubProblem} className="border rounded">
            저장하기
        </button>
    </div>
}

export function SaveButtonForUhui({paragraph_content, uhui, answer_str, options})
{
    const postProblem = async (problemToSave) => {
        const response = await axios.post(API_BASE_URL, {
            jwt : sessionStorage.getItem("jwt"),
            action : "save_problem",
            data : problemToSave
        });
        console.log(response)
        alert("저장되었습니다!")
    }
    const saveUhuiProblem = () => {
        let problemToSave = {}
        problemToSave = {
            "problem_type" : "uhui",
            "paragraph" : {
                "content" : paragraph_content,
                "translation" : null
            },
            "footnote": null,
            "uhui" : uhui,
            "answer_str" : answer_str,            
            "options" : options,
            "explanation" : null,
        }
        console.log("PROBLEM_TO_SAVE :", problemToSave)
        postProblem(problemToSave)
    }
    return <div>
        <button onClick={saveUhuiProblem} className="border rounded">
            저장하기
        </button>
    </div>
}