import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';

import API_BASE_URL from '../utils/API_BASE_URL'

import Home from './pages/Home';
import MyMaterials from './pages/MyMaterials';
import BasedCreation from './pages/BasedCreation';
import FileBasedCreation from './pages/FileBasedCreation';
import Transformation from './pages/Transformation'

function Main() {
    const [jwt, setJwt] = useState(sessionStorage.getItem('jwt'));

    function isJwtValid(token) {
        if (!token) return false;
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp > currentTime;
    }

    if (!jwt || !isJwtValid(jwt)) {
        return <div className='container'>
            <LoginForm setJwt={setJwt} />
        </div>
    }

    return (
        
        <Router>
            <NavBarWithLogoutButton jwt={jwt} setJwt={setJwt} />        
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/my-materials" element={<MyMaterials />} />
                <Route path="/based-creation" element={<BasedCreation />} />
                <Route path="/file-based-creation" element={<FileBasedCreation />} />
                <Route path="/transformation" element={<Transformation/>} />
            </Routes>
        </Router>
        
    );
}

function LoginForm({ setJwt }) {
    const [userCode, setUserCode] = useState('');
    
    async function handleLogin() {
        try {
            const response = await axios.post(API_BASE_URL, {
                "action": "login",
                "user_code": userCode
            });
            console.log(response.data);
            sessionStorage.setItem('jwt', response.data.jwt);
            setJwt(response.data.jwt);
        } catch (error) {
            console.error("로그인 실패:", error);
        }
    }

    return <>
            <div className='d-flex flex-column justify-content-center my-5'>
                <input 
                    id="password-input" 
                    type="password" 
                    onChange={(e) => setUserCode(e.target.value)}                
                />
                <button className="border rounded" onClick={handleLogin}>로그인</button>
            </div>
        </>
}    

function NavBarWithLogoutButton({jwt, setJwt}) {
    function handleLogout(setJwt) {
        sessionStorage.removeItem('jwt');
        setJwt(null);
    }
    return (
        <div className='container'>
            <div className="d-flex flex-row justify-content-center border rounded">
                <Link to="/" className="btn mx-2"><strong>홈</strong></Link>
                <Link to="/my-materials" className="btn mx-2"><strong>내 자료</strong></Link>
                <button onClick={() => handleLogout(setJwt)} className="btn mx-2"><strong>로그아웃</strong></button>
            </div>
        </div>
    );
}

export default Main;

