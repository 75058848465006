import axios from "axios";
import { useState, useEffect } from "react";
import API_BASE_URL from "../utils/API_BASE_URL";

export default function MyTickets() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.post(API_BASE_URL, {
                    action: 'get_my_tickets',
                    jwt: sessionStorage.getItem('jwt')
                });
                console.log("data", data);
                setData(data);
            } catch (error) {
                console.error('Error fetching tickets:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        })(); // 함수 선언과 동시에 즉시 호출
    }, []);

    if (loading) return <div className="text-center mt-5">로딩 중...</div>;
    if (error) return <div className="text-center mt-5">에러 발생: {error.message}</div>;

    return (
        <div className="my-1">
            <Greetings userName={data.user_name} />
                <div className="d-flex justify-content-center border rounded px-1 py-1">
                    <TicketInfo title="기타(빈칸 외)" count={data.problem_tickets.not_binkan} />
                    <TicketInfo title="빈칸" count={data.problem_tickets.binkan} />
                    <TicketInfo title="파일(pdf)" count={data.file_tickets.pdf} />
                </div>
        </div>
    );
}

function Greetings({ userName }) {
    return (
        <div className="d-flex justify-content-end my-2">
            <span><strong>{userName}</strong> 님, 안녕하세요!</span>
        </div>
    );
}

function TicketInfo({ title, count }) {
    return (
        <div className="d-flex flex-column mx-3 align-items-center text-center">
            <div className="mb-1"><strong>{title}</strong></div>
            <div className="border p-2 rounded">{count}</div>
        </div>
    );
}
