
export const problemDictionary = {
    "juje": {
        "interface_type" : "OPTIONS_ONLY",
        "ksat_question_string" : "다음 글의 주제로 가장 적절한 것은?",
        "metaText" : false,
        "kor_type_name" : "주제"
    },
    "jemok": {
        "interface_type" : "OPTIONS_ONLY",
        "ksat_question_string" : "다음 글의 제목으로 가장 적절한 것은?",
        "metaText" : false,
        "kor_type_name" : "제목"
    },
    "yoji": {
        "interface_type" : "OPTIONS_ONLY",
        "ksat_question_string" : "다음 글의 요지로 가장 적절한 것은?",
        "metaText" : false,
        "kor_type_name" : "요지"
    },
    "mokjuk": {
        "interface_type" : "OPTIONS_ONLY",
        "ksat_question_string" : "다음 글의 목적으로 가장 적절한 것은?",
        "metaText" : false,
        "kor_type_name" : "목적"
    },
    "jujang" : {
        "interface_type" : "OPTIONS_ONLY",
        "ksat_question_string" : "다음 글이 주장하는 바로 가장 적절한 것은?",
        "metaText" : false,
        "kor_type_name" : "주장"
    },
    "sunseo": {
        "interface_type" : "PROBLEMIZE_ONLY",
        "ksat_question_string" : "주어진 글 다음에 이어질 글의 순서로 가장 적절한 것을 고르시오.",
        "metaText" : false,
        "kor_type_name" : "순서"
    },
    "sabib": {
        "interface_type" : "PROBLEMIZE_ONLY",
        "ksat_question_string" : "글의 흐름으로 보아, 주어진 문장이 들어가기에 가장 적절한 곳을 고르시오.",
        "metaText" : "given_text",
        "kor_type_name" : "삽입"
    },
    "binkan": {
        "interface_type" : "PROBLEMIZE_AND_OPTIONS",
        "ksat_question_string" : "다음 빈칸에 들어갈 말로 가장 적절한 것을 고르시오.",
        "metaText" : "answer_str",
        "kor_type_name" : "빈칸"
    },
    "hamui": {
        "interface_type" : "PROBLEMIZE_AND_OPTIONS",
        "ksat_question_string" : "밑줄 친 어구가 다음 글에서 의미하는 바로 가장 적절한 것은?",
        "metaText" : "given_text",
        "kor_type_name" : "함의"
    },
    "ubub" : {
        "interface_type" : "PROBLEMIZE_ONLY",
        "ksat_question_string" : "다음 중 어법상 틀린 것은?",
        "metaText" : false,
        "kor_type_name" : "어법"
    },
    "uhui" : {
        "interface_type" : "PROBLEMIZE_ONLY",
        "ksat_question_string" : "다음 중 낱말의 쓰임이 적절하지 않은 것은?",
        "metaText" : false,
        "kor_type_name" : "어휘"
    }
}

