import { useState, useEffect } from 'react';

import InitInterface from '../Interfaces/InitInterface';
import OptionOnlyInterface from '../Interfaces/OptionOnlyInterface';
import ProblemizeOnlyInterface from '../Interfaces/ProblemizeOnlyInterface';
import ProblemizeAndOptionInterface from '../Interfaces/ProblemizeAndOptionInterface';
import { problemDictionary } from '../../utils/problemDictionary';

export default function BasedCreation() {
    const [inputParagraph, setInputParagraph] = useState('');
    const [fixedParagraph, setFixedParagraph] = useState('')
    const [selectedProblemType, setSelectedProblemType] = useState(null);
    const [fixed, setFixed] = useState(false);
    const [interfaceType, setInterfaceType] = useState(null);

    useEffect(() => {
        if (fixed && problemDictionary[selectedProblemType]) {
            setInterfaceType(problemDictionary[selectedProblemType]['interface_type']);
        }
    }, [fixed, selectedProblemType]);
    
    return (
        <div className="container">
            <h4 className='mt-3'>입력기반 생성</h4>
            <InitInterface
                inputParagraph={inputParagraph} setInputParagraph={setInputParagraph}
                fixedParagraph={fixedParagraph} setFixedParagraph={setFixedParagraph}
                selectedProblemType={selectedProblemType} setSelectedProblemType={setSelectedProblemType}                
                fixed={fixed} setFixed={setFixed}
                setInterfaceType={setInterfaceType}
            />
            {fixed && interfaceType === "OPTIONS_ONLY" && (
                <>
                    <hr />
                    <OptionOnlyInterface 
                        selectedProblemType={selectedProblemType}
                        fixedParagraph={fixedParagraph} />                        
                </>
            )}
            {fixed && interfaceType === "PROBLEMIZE_ONLY" && (
                <>
                    <hr />
                    <ProblemizeOnlyInterface 
                        selectedProblemType={selectedProblemType}
                        fixedParagraph={fixedParagraph} />                        
                </>
            )}
            {fixed && interfaceType === "PROBLEMIZE_AND_OPTIONS" && (
                <>
                    <hr />
                    <ProblemizeAndOptionInterface
                        selectedProblemType={selectedProblemType}
                        fixedParagraph={fixedParagraph} />
                </>
            )}
        </div>
    );
}

