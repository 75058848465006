// import './App.css';
import Header from './Components/Header';
import Main from './Components/Main';
import Footer from './Components/Footer';
import AppContext from './GLOBAL_STATE/AppContext';
import { useState } from 'react';

function App() {

  const [basedCraetionState, setBasedCreationState] = useState({
    'loading' : false,
    'logs' : [],
  })
  const [fileBasedCreationState, setFileBasedCreationState] = useState({
    'fileName' : null,
    'materials' : [],
    "loading " : false 
  })


  return (
    <AppContext.Provider value={{fileBasedCreationState, setFileBasedCreationState}}>
      <div className="App">
        <Header />
        <Main/>
        <Footer/>
      </div>
    </AppContext.Provider>
  );
}

export default App;
