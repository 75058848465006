import { useEffect, useState } from "react";
import axios from "axios";

import { problemDictionary } from "../../utils/problemDictionary";
import API_BASE_URL from "../../utils/API_BASE_URL";
import { SaveButtonForSabib, SaveButtonForSunseo, SaveButtonForUbub, SaveButtonForUhui } from "../SaveButton";
import underlineGrammarWord from "../../utils/underlineGrammarWord";
import underlineUhuiWord from "../../utils/underlineUhuiWord";

export default function ProblemizeOnlyInterface({ selectedProblemType, fixedParagraph }) {
    const [logs, setLogs] = useState([]);

    // [순서], [삽입] 모두 해당
    const [aiGivenText, setAiGivenText] = useState(""); 
    const [aiAnswerStr, setAiAnswerStr] = useState("");

    // [순서]의 경우 아래 상태를 추가로 가짐
    const [aiSplittedParagraphs, setAiSplittedParagraphs] = useState([]); 

    // [삽입]의 경우 아래 상태를 추가로 가짐
    const [aiPoppedParagraph, setAiPoppedParagraph] = useState("");

    // [어법]의 경우 가지는 상태들
    const [aiProblemizedUbub, setAiProblemizedUbub] = useState(null)

    // [어휘]의 경우 가지는 상태들
    const [aiProblemizedUhui, setAiProblemizedUhui] = useState(null)

    const questionString = problemDictionary[selectedProblemType]?.ksat_question_string;

    const fetchProblemize = async () => {
        const response = await axios.post(
            API_BASE_URL,
            {
                jwt : sessionStorage.getItem("jwt"),
                action : "build_problemize_component",
                problem_type: selectedProblemType,
                paragraph_content: fixedParagraph,
            }
        )
        if (selectedProblemType === "sabib"){
            setAiGivenText(response.data.given_text)
            setAiPoppedParagraph(response.data.content)
            setAiAnswerStr(response.data.answer_no)
        } else if (selectedProblemType === "sunseo"){
            setAiSplittedParagraphs(response.data.paragraphs)
            setAiGivenText(response.data.given_text)
            setAiAnswerStr(response.data.answer_str)
        } else if (selectedProblemType === "ubub"){
            setAiProblemizedUbub(response.data)
        } else if (selectedProblemType ==="uhui") {
            setAiProblemizedUhui(response.data)
        }
        if (response.data.log) setLogs([...logs, response.data.log])
    }

    useEffect(()=>{
        fetchProblemize()
    },[])

    return (
        <div className='container border rounded my-3 py-2'>
            <div><strong>{questionString}</strong></div>

            {/* [순서] 유형 렌더링 */}
            {selectedProblemType==="sunseo" && aiSplittedParagraphs.length === 3 && aiGivenText !== "" && (
                <div>
                    <div className="my-2"><i>{aiGivenText}</i></div>
                    {aiSplittedParagraphs.map((paragraph, index) => (
                        <div key={index} className="my-1">
                            <strong>({String.fromCharCode(65 + index)})</strong> {paragraph.content}
                        </div>
                    ))} 
                    <div className="my-2"><strong>정답 : <mark>{aiAnswerStr}</mark></strong></div>

                    <div className="d-flex justify-content-center">
                        <SaveButtonForSunseo
                            paragraphs={aiSplittedParagraphs}
                            given_text={aiGivenText}
                            given_text_writer={"ai"}
                            answer_str = {aiAnswerStr}
                        />
                    </div>
                </div>
            )}

            {/* [삽입] 유형 렌더링 */}
            {selectedProblemType==="sabib" && aiGivenText !== "" && aiAnswerStr !== "" && (
                <div>
                    <div className="my-2"><i>{aiGivenText}</i></div>
                    <p>{aiPoppedParagraph}</p>
                    <div className="my-2"><strong>정답 : <mark>{aiAnswerStr}</mark></strong></div>
                    <div className="d-flex justify-content-center">
                        <SaveButtonForSabib
                            paragraph_content={aiPoppedParagraph}
                            given_text={aiGivenText}
                            given_text_writer={"ai"}
                            answer_str = {aiAnswerStr}
                        />
                    </div>
                </div>
            )}

            {/* [어법] 유형 렌더링 */}
            {selectedProblemType==="ubub" && aiProblemizedUbub !== null && (
                <div>
                    {/* <div className="my-2"><i>{aiGivenText}</i></div> */}
                    <p dangerouslySetInnerHTML={{ __html: underlineGrammarWord(aiProblemizedUbub['paragraph_content']) }}></p>
                    <div className="my-2"><strong>정답 : <mark>{aiProblemizedUbub['answer_str']}</mark></strong></div>
                    <div className="d-flex justify-content-center">
                        <SaveButtonForUbub paragraph_content={aiProblemizedUbub['paragraph_content']}
                                            grammar={aiProblemizedUbub['grammar']}
                                            answer_str={aiProblemizedUbub['answer_str']}
                                            options = {aiProblemizedUbub['options']} />
                    </div>
                </div>
            )}

            {/* [어휘] 유형 렌더링 */}
            {selectedProblemType==="uhui" && aiProblemizedUhui !== null && (
                <div>
                    {/* <div className="my-2"><i>{aiGivenText}</i></div> */}
                    <p dangerouslySetInnerHTML={{ __html: underlineUhuiWord(aiProblemizedUhui['paragraph_content']) }}></p>
                    <div className="my-2"><strong>정답 : <mark>{aiProblemizedUhui['answer_str']}</mark></strong></div>
                    <div className="d-flex justify-content-center">
                        <SaveButtonForUhui paragraph_content={aiProblemizedUhui['paragraph_content']}
                                            uhui={aiProblemizedUhui['uhui']}
                                            answer_str={aiProblemizedUhui['answer_str']}
                                            options = {aiProblemizedUhui['options']} />
                    </div>
                </div>
            )}
        </div>
    );
}
