export function elementIdToHTML(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        let htmlContent = element.outerHTML;

        // Bootstrap CDN link
        const bootstrapCDN = `
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>`;

        // Wrap the HTML content with a basic HTML structure
        htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Document</title>
            ${bootstrapCDN}
        </head>
        <body>
            ${htmlContent}
        </body>
        </html>`;

        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        a.href = url;
        a.download = 'document.html';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release the URL object
        URL.revokeObjectURL(url);
    } else {
        console.error(`Element with id ${elementId} not found.`);
    }
}

