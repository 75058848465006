import axios from 'axios';
import { useState, useEffect } from 'react';

import API_BASE_URL from '../../utils/API_BASE_URL';
import { problemDictionary } from '../../utils/problemDictionary';
import SaveButton from '../SaveButton'
import { SaveButtonForBinkan, SaveButtonForHamui } from '../SaveButton';


export default function ProblemizeAndOptionInterface({ selectedProblemType, fixedParagraph }) {
    const questionString = problemDictionary[selectedProblemType]['ksat_question_string'];

    const [logs, setLogs] = useState([]);

    // [빈칸] : 문단의 ansewr_str에 해당하는 부분이 뚫림
    const [aiAnswerString, setAiAnswerString] = useState(""); // [API]
    const [userAnswerString, setUserAnswerString] = useState(""); // [API]
    const [aiBlankedParagraph, setAiBlankedParagraph] = useState(""); // [주의] 렌더링용 [String]. API콜에 사용하지 말 것. 
    
    const [answerStringWriter, setAnswerStringWriter] = useState(""); // [API] ai OR user
    const [confirmedAnswerString, setConfirmedAnswerString] = useState(""); // [API]

    // [함의] : 문단의 given_text에 해당하는 부분에 밑줄이 그임
    const [aiGivenText, setAiGivenText] = useState("")
    const [userGivenText, setUserGivenText] = useState("")
    const [aiUnderlinedParagraph, setAiUnderlinedParagraph] = useState(""); // [주의] 렌더링용 [innerHTML]. API콜에 사용하지 말 것. 
    
    const [givenTextWriter, setGivenTextWriter] = useState("") // [API] ai OR user
    const [confirmedGivenText, setConfirmedGivenText] = useState("")

    const fetchProblemizeComponent = async () => {
        try {
            const response = await axios.post(API_BASE_URL, {
                jwt: sessionStorage.getItem('jwt'),
                action: "build_problemize_component",
                problem_type: selectedProblemType,
                paragraph_content: fixedParagraph
            });
            console.log(response.data);
            if (!response.data) {
                window.alert("네트워크 오류가 발생했습니다");
                return;
            }
            if (selectedProblemType === "binkan") {
                const updatedContent = response.data.content.replace(/\[BLANK\]/g, '_______________');
                setAiBlankedParagraph(updatedContent);
                setAiAnswerString(response.data.answer_str);
            } else if (selectedProblemType === "hamui") {
                const updatedContent = response.data.content.replace(/\[UNDERLINE_START\]/g, '<u>').replace(/\[UNDERLINE_END\]/g, '</u>');
                console.log("updatedContent :", updatedContent);
                setAiUnderlinedParagraph(updatedContent);
                setAiGivenText(response.data.given_text);
            } else {
                console.error("알 수 없는 문제 유형:", selectedProblemType);
            }
        } catch (error) {
            console.error("API 호출 중 오류 발생:", error);
            window.alert("네트워크 오류가 발생했습니다");
        }
    };

    useEffect(() => {
        fetchProblemizeComponent();
    }, []);

    return (
        <div>
            {/* [빈칸] 렌더링 */}
            {selectedProblemType === "binkan" && (
            <>
                <div className='d-flex'>                    
                    {/* [AI] 빈칸 뚫기 */}                    
                    <div className='container border rounded py-2 mx-1'>
                        <div><strong>{questionString}</strong></div>
                        <p>{aiBlankedParagraph || fixedParagraph}</p>
                        <div><strong>큐봇</strong> : {aiAnswerString}</div>                                                  
                    </div>                                                           
                    {/* [유저] 빈칸 뚫기*/}
                    <div className='container border rounded py-2 mx-1'>
                        <div><strong>{questionString}</strong></div>
                        <UnderlinableBinkanParagraph 
                            fullString={fixedParagraph} 
                            setUserAnswerString={setUserAnswerString} 
                            answerStringWriter={answerStringWriter}
                        />
                        <div><strong>유저</strong> : {userAnswerString || "드래그하여 빈칸을 설정해주세요"}</div>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='col-6 text-center'>
                        <button 
                            className='w-70 border rounded' 
                            onClick={function() {
                                if (aiAnswerString && window.confirm("빈칸설정을 확정할까요?")) {
                                    setAnswerStringWriter("ai");
                                    setConfirmedAnswerString(aiAnswerString);
                                } else {
                                    return;
                                }
                            }}
                            disabled={answerStringWriter !== ""}
                        >
                            큐봇이 설정한 빈칸 사용하기
                        </button>
                    </div>
                    <div className='col-6 text-center'>
                        <button 
                            className='w-70 border rounded' 
                            onClick={function() {
                                if (userAnswerString && window.confirm("빈칸설정을 확정할까요?")) {
                                    setAnswerStringWriter("user");
                                    setConfirmedAnswerString(userAnswerString);
                                } else {
                                    return;
                                }
                            }}
                            disabled={answerStringWriter !== ""}
                        >
                            내가 설정한 빈칸 사용하기
                        </button>
                    </div>
                </div>
                {answerStringWriter && confirmedAnswerString && (
                    <BinkanOptionGenInterface
                        fullString={fixedParagraph}
                        confirmedAnswerString={confirmedAnswerString}
                        answerStringWriter={answerStringWriter}
                    />
                )}
            </>
            )}

            {/* [함의] 렌더링 */}
            {selectedProblemType === "hamui" && (
            <>
                <div className='d-flex'>                    
                    {/* [AI] 함의를 물을 구절 설정*/}                    
                    <div className='container border rounded py-2 mx-1'>
                        <div><strong>{questionString}</strong></div>
                        <p dangerouslySetInnerHTML={{ __html: aiUnderlinedParagraph || fixedParagraph }}></p>
                        <div><strong>큐봇</strong> : {aiGivenText}</div>                                                  
                    </div>                                                           
                    {/* [유저] 함의를 물을 구절 설정*/}
                    <div className='container border rounded py-2 mx-1'>
                        <div><strong>{questionString}</strong></div>
                        <UnderlinableHamuiParagraph
                            fullString={fixedParagraph}
                            setUserGivenText={setUserGivenText}
                            givenTextWriter={givenTextWriter}
                        />
                        <div><strong>유저</strong> : {userGivenText || "드래그하여 함의구문을 설정해주세요"}</div>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='col-6 text-center'>
                        <button 
                            className='w-70 border rounded' 
                            onClick={function() {
                                if (aiGivenText && window.confirm("함의구문을 확정할까요?")) {
                                    setGivenTextWriter("ai");
                                    setConfirmedGivenText(aiGivenText);
                                } else {
                                    return;
                                }
                            }}
                            disabled={givenTextWriter !== ""}
                        >
                            큐봇이 설정한 함의구문 사용하기
                        </button>
                    </div>
                    <div className='col-6 text-center'>
                        <button 
                            className='w-70 border rounded' 
                            onClick={function() {
                                if (userGivenText && window.confirm("함의구문을 확정할까요?")) {
                                    setGivenTextWriter("user");
                                    setConfirmedGivenText(userGivenText);
                                } else {
                                    return;
                                }
                            }}
                            disabled={givenTextWriter !== ""}
                        >
                            내가 설정한 함의구문 사용하기
                        </button>
                    </div>
                </div>
                {givenTextWriter && confirmedGivenText && (
                    <HamuiOptionGenInterface
                        fullString={fixedParagraph}
                        confirmedGivenText={confirmedGivenText}
                        givenTextWriter={givenTextWriter}
                    />
                )}
            </>
            )}
        </div>
    );
}

function UnderlinableBinkanParagraph({ fullString, setUserAnswerString, answerStringWriter }) {
    const [draggedText, setDraggedText] = useState("");

    const handleMouseUp = () => {
        if (answerStringWriter) return; // answerStringWriter가 설정되면 드래그 불가
        const selectedText = window.getSelection().toString();
        if (selectedText) {
            setDraggedText(selectedText);
        }
    };

    useEffect(() => {
        setUserAnswerString(draggedText);
    }, [draggedText]);

    return (
        <div onMouseUp={handleMouseUp}>
            {draggedText && (
                <p>
                    {fullString.split(draggedText).reduce((prev, current, i) => {
                        if (!i) return [current];
                        return prev.concat(<u key={i}>{draggedText}</u>, current);
                    }, [])}
                </p>
            )}
            {!draggedText && (
                <p>{fullString}</p>
            )}
        </div>
    );
}

function UnderlinableHamuiParagraph({fullString, setUserGivenText, givenTextWriter}) {
    const [draggedText, setDraggedText] = useState("");

    const handleMouseUp = () => {
        if (givenTextWriter) return; // givenTextWriter가 설정되면 드래그 불가
        const selectedText = window.getSelection().toString();
        if (selectedText) {
            setDraggedText(selectedText);
        }
    };

    useEffect(() => {
        setUserGivenText(draggedText);
    }, [draggedText]);

    return (
        <div onMouseUp={handleMouseUp}>
            {draggedText && (
                <p>
                    {fullString.split(draggedText).reduce((prev, current, i) => {
                        if (!i) return [current];
                        return prev.concat(<u key={i}>{draggedText}</u>, current);
                    }, [])}
                </p>
            )}
            {!draggedText && (
                <p>{fullString}</p>
            )}
        </div>
    );
}

function BinkanOptionGenInterface({ fullString, confirmedAnswerString, answerStringWriter }) {
    const [finalParagraphContent, setFinalParagraphContent] = useState("");
    const [finalParagraphView, setFinalParagraphView] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const fetchOptions = async () => {
        const response = await axios.post(API_BASE_URL, {
            jwt: sessionStorage.getItem('jwt'),
            action: "build_options_component",
            problem_type: "binkan",
            paragraph_content: finalParagraphContent,
            answer_str: confirmedAnswerString
        });
        console.log(response.data);
        setOptions([...options, ...response.data.options]);
    };

    useEffect(() => {
        const blankedContent = fullString.replace(confirmedAnswerString, "[BLANK]");
        const blankedView = fullString.replace(confirmedAnswerString, "_______________");

        setFinalParagraphContent(blankedContent);
        setFinalParagraphView(blankedView);
        fetchOptions();
    }, []);

    useEffect(() => {
        console.log(selectedOptions);
    }, [selectedOptions]);

    return (
        <>
        <hr/>
        <div className='container border rounded p-3'>
            <div>
                <strong>{problemDictionary['binkan']['ksat_question_string']}</strong>
                <p>{finalParagraphView}</p>
            </div>
            <p>(로딩 시각화 작업중..) 선지는 5 개 자동생성되며, '더 만들기'를 통해 더 만들 수 있습니다. 선지를 5 개 불러오는 데 약 3초-10초 정도 걸립니다.</p>
            {options && options.map((option, index) => {
                const handleCheckboxChange = (e) => {
                    if (e.target.checked) {
                        setSelectedOptions([...selectedOptions, option]);
                    } else {
                        setSelectedOptions(selectedOptions.filter(o => o !== option));
                    }
                };

                return (
                    <div key={index}>
                        <input type="checkbox" onChange={handleCheckboxChange} className='me-1'/>
                        {option.is_correct ? (
                            <mark>{option.content}</mark>
                        ) : (
                            <>
                                {option.content} : <small>{option.odap_type}</small>
                            </>
                        )}
                    </div>
                );
            })}
            <div className='d-flex justify-content-center'>
                <button onClick={()=>{
                    alert("선지를 불러오는 데 3초-10초 정도 소요됩니다! (로딩 시각화 작업중..)"); fetchOptions() }} className='border rounded' style={{ width: '40%' }}>선지 더 만들기</button>
                <SaveButtonForBinkan
                    paragraph_content={finalParagraphContent}
                    answer_str={confirmedAnswerString}
                    answer_str_writer={answerStringWriter}
                    options={selectedOptions}
                />
            </div>
            <a href='https://qbot-options.streamlit.app/' target="_blank">선지 커스텀(신기능)</a>
        </div>
        </>
    );
}

function HamuiOptionGenInterface({ fullString, confirmedGivenText, givenTextWriter }) {
    const [finalParagraphContent, setFinalParagraphContent] = useState("");
    const [finalParagraphView, setFinalParagraphView] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const fetchOptions = async () => {
        const response = await axios.post(API_BASE_URL, {
            jwt: sessionStorage.getItem('jwt'),
            action: "build_options_component",
            problem_type: "hamui",
            paragraph_content: finalParagraphContent,
            given_text: confirmedGivenText
        });
        console.log(response.data);
        setOptions([...options, ...response.data.options]);
    };

    useEffect(() => {
        const underlinedContent = fullString.replace(confirmedGivenText, `[UNDERLINE_START]${confirmedGivenText}[UNDERLINE_END]`);
        const underlinedView = fullString.replace(confirmedGivenText, `<u>${confirmedGivenText}</u>`);

        setFinalParagraphContent(underlinedContent);
        setFinalParagraphView(underlinedView);
        fetchOptions();
    }, []);

    useEffect(() => {
        console.log(selectedOptions);
    }, [selectedOptions]);

    return (
        <>
        <hr/>
        <div className='container border rounded p-3'>
            <div>
                <strong>{problemDictionary['hamui']['ksat_question_string']}</strong>
                <p dangerouslySetInnerHTML={{ __html: finalParagraphView }}></p>
            </div>
            <p>(로딩 시각화 작업중..) 선지는 5 개 자동생성되며, '더 만들기'를 통해 더 만들 수 있습니다. 선지를 5 개 불러오는 데 약 3초-10초 정도 걸립니다.</p>
            {options && options.map((option, index) => {
                const handleCheckboxChange = (e) => {
                    if (e.target.checked) {
                        setSelectedOptions([...selectedOptions, option]);
                    } else {
                        setSelectedOptions(selectedOptions.filter(o => o !== option));
                    }
                };

                return (
                    <div key={index}>
                        <input type="checkbox" onChange={handleCheckboxChange} className='me-1'/>
                        {option.is_correct ? (
                            <mark>{option.content}</mark>
                        ) : (
                            <>
                                {option.content} : <small>{option.odap_type}</small>
                            </>
                        )}
                    </div>
                );
            })}
            <div className='d-flex justify-content-center'>
                <button onClick={()=>{
                    alert("선지를 불러오는 데 3초-10초 정도 소요됩니다! (로딩 시각화 작업중..)"); fetchOptions() }} className='border rounded' style={{ width: '40%' }}>선지 더 만들기</button>
                <SaveButtonForHamui
                    paragraph_content={finalParagraphContent}
                    given_text={confirmedGivenText}
                    given_text_writer={"ai"}
                    options={selectedOptions}
                />
            </div>
            <a href='https://qbot-options.streamlit.app/' target="_blank">선지 커스텀(신기능)</a>
        </div>
        </>
    );
}

