import { problemDictionary } from "../utils/problemDictionary";
import {
  binkanContentToView,
  hamuiContentToView,
} from "../utils/paragraphContentToView";
import { elementIdToHTML } from "../utils/problemViewToHTML";
import underlineGrammarWord from "../utils/underlineGrammarWord";
import underlineUhuiWord from "../utils/underlineUhuiWord";

export default function MaterialView({ material }) {
  switch (material.problem_type) {
    case "binkan":
      return (
        <div className="container border rounded py-3 my-3">
          <BinkanView
            downloadElementId={material.problem_id}
            problem={material}
          />
          <MaterialDownloadButton
            onClick={() => elementIdToHTML(material.problem_id)}
          />
        </div>
      );
    case "hamui":
      return (
        <div className="container border rounded py-3 my-3">
          <HamuiView
            downloadElementId={material.problem_id}
            problem={material}
          />
          <MaterialDownloadButton
            onClick={() => elementIdToHTML(material.problem_id)}
          />
        </div>
      );
    case "sunseo":
      return (
        <div className="container border rounded py-3 my-3">
          <SunseoView
            downloadElementId={material.problem_id}
            problem={material}
          />
          <MaterialDownloadButton
            onClick={() => elementIdToHTML(material.problem_id)}
          />
        </div>
      );
    case "sabib":
      return (
        <div className="container border rounded py-3 my-3">
          <SabibView
            downloadElementId={material.problem_id}
            problem={material}
          />
          <MaterialDownloadButton
            onClick={() => elementIdToHTML(material.problem_id)}
          />
        </div>
      );
    case "ubub":
      return (
        <div className="container border rounded py-3 my-3">
          <UbubView
            downloadElementId={material.problem_id}
            problem={material}
          />{" "}
          <MaterialDownloadButton
            onClick={() => elementIdToHTML(material.problem_id)}
          />
        </div>
      );
    case "uhui":
      console.log(material);

      return (
        <div className="container border rounded py-3 my-3">
          <UhuiView
            downloadElementId={material.problem_id}
            problem={material}
          />
          <MaterialDownloadButton
            onClick={() => elementIdToHTML(material.problem_id)}
          />
        </div>
      );
    default:
      return (
        <div className="container border rounded py-3 my-3">
          <DaeuiView
            downloadElementId={material.problem_id}
            problem={material}
          />
          <MaterialDownloadButton
            onClick={() => elementIdToHTML(material.problem_id)}
          />
        </div>
      );
  }
}

// 작업중
function MaterialDownloadButton({ onClick }) {
  return (
    <div className="d-flex justify-content-center">
      <button className="border rounded" onClick={onClick}>
        다운로드
      </button>
    </div>
  );
}

function BinkanView({ downloadElementId, problem }) {
  return (
    <div id={downloadElementId}>
      <div className="container border rounded my-3 py-3">
        <strong>
          <p>빈칸 : {problem.problem_id}</p>
        </strong>
        <strong>
          <p>{problem.timestamp}</p>
        </strong>
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["ksat_question_string"]}
          </p>
        </strong>
        {binkanContentToView(problem.paragraph.content)}
        <div>
          {problem.options.map((option) => {
            return (
              (option.is_correct && (
                <div key={option.content}>
                  <mark>{option.content}</mark>
                </div>
              )) ||
              (!option.is_correct && (
                <div key={option.content}>
                  {option.content} :{" "}
                  <small>
                    <i>{option.odap_type}</i>
                  </small>
                </div>
              ))
            );
          })}
        </div>
        {problem.source_text && problem.source_file && (
          <>
            <hr />
            <details>
              <summary>원문 보기 : {problem.source_file}</summary>
              <p>{problem.source_text}</p>
            </details>
          </>
        )}
      </div>
    </div>
  );
}

function HamuiView({ downloadElementId, problem }) {
  return (
    <div id={downloadElementId}>
      <div className="container border rounded my-3 py-3">
        <strong>
          <p>함의 : {problem.problem_id}</p>
        </strong>
        <strong>
          <p>{problem.timestamp}</p>
        </strong>
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["ksat_question_string"]}
          </p>
        </strong>
        {hamuiContentToView(problem.paragraph.content)}
        <div>
          {problem.options.map((option) => {
            if (option.is_correct) {
              return (
                <div key={option.content}>
                  <mark>{option.content}</mark>
                </div>
              );
            } else {
              return (
                <div key={option.content}>
                  {option.content} :{" "}
                  <small>
                    <i>{option.odap_type}</i>
                  </small>
                </div>
              );
            }
          })}
        </div>
        {problem.source_text && problem.source_file && (
          <>
            <hr />
            <details>
              <summary>원문 보기 : {problem.source_file}</summary>
              <p>{problem.source_text}</p>
            </details>
          </>
        )}
      </div>
    </div>
  );
}

function SunseoView({ downloadElementId, problem }) {
  return (
    <div id={downloadElementId}>
      <div className="container border rounded my-3 py-3">
        <strong>
          <p>순서 : {problem.problem_id}</p>
        </strong>
        <strong>
          <p>타임스탬프 : {problem.timestamp}</p>
        </strong>
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["ksat_question_string"]}
          </p>
        </strong>
        <p>
          {" "}
          <i>{problem.given_text}</i>{" "}
        </p>
        {problem.paragraphs.map((paragraph, index) => (
          <p key={index}>
            ({String.fromCharCode(65 + index)}) {paragraph.content}
          </p>
        ))}
        <div>
          <strong>
            정답 : <mark>{problem.answer_str}</mark>
          </strong>
        </div>

        {problem.source_text && problem.source_file && (
          <>
            <hr />
            <details>
              <summary>원문 보기 : {problem.source_file}</summary>
              <p>{problem.source_text}</p>
            </details>
          </>
        )}
      </div>
    </div>
  );
}

function SabibView({ downloadElementId, problem }) {
  return (
    <div id={downloadElementId}>
      <div className="container border rounded my-3 py-3">
        <strong>
          <p>삽입 : {problem.problem_id}</p>
        </strong>
        <strong>
          <p>{problem.timestamp}</p>
        </strong>
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["ksat_question_string"]}
          </p>
        </strong>
        <p>
          {" "}
          <i>{problem.given_text}</i>{" "}
        </p>
        <div> {problem.paragraph.content} </div>
        <div> 정답 : {problem.answer_str} </div>
        {/* <pre>{JSON.stringify(problem, null, 2)}</pre> */}
      </div>
    </div>
  );
}

function DaeuiView({ downloadElementId, problem }) {
  const problemTypeData = problemDictionary[problem.problem_type];
  if (!problemTypeData) {
    console.error(`Unknown problem type: ${problem.problem_type}`);
    return null;
  }

  return (
    <div id={downloadElementId}>
      <div className="container border rounded my-3 py-3">
        <strong>
          <p>
            {problemTypeData["kor_type_name"]} : {problem.problem_id}
          </p>
        </strong>
        <strong>
          <p>{problem.timestamp}</p>
        </strong>
        <strong>
          <p>{problemTypeData["ksat_question_string"]}</p>
        </strong>
        <div> {problem.paragraph.content} </div>
        <br />
        <div>
          {problem.options.map((option) => {
            return (
              (option.is_correct && (
                <div key={option.content}>
                  <mark>{option.content}</mark>
                </div>
              )) ||
              (!option.is_correct && (
                <div key={option.content}>
                  {option.content} :{" "}
                  <small>
                    <i>{option.odap_type}</i>
                  </small>
                </div>
              ))
            );
          })}
        </div>
        {problem.source_text && problem.source_file && (
          <>
            <hr />
            <details>
              <summary>원문 보기 : {problem.source_file}</summary>
              <p>{problem.source_text}</p>
            </details>
          </>
        )}
      </div>
    </div>
  );
}

function UbubView({ downloadElementId, problem }) {
  return (
    <div id={downloadElementId}>
      <div className="container border rounded my-3 py-3">
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["kor_type_name"]} :{" "}
            {problem.problem_id}
          </p>
        </strong>
        <strong>
          <p>{problem.timestamp}</p>
        </strong>
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["ksat_question_string"]}
          </p>
        </strong>

        <div
          dangerouslySetInnerHTML={{
            __html: underlineGrammarWord(problem.paragraph.content),
          }}
        />

        <br />
        <div>
          <mark
            dangerouslySetInnerHTML={{
              __html: problem.grammar.correct_grammar
                ? "정답: " +
                  underlineGrammarWord(problem.grammar.correct_grammar)
                : "No correct grammar provided",
            }}
          ></mark>
        </div>
        {problem.source_text && problem.source_file && (
          <>
            <hr />
            <details>
              <summary>원문 보기 : {problem.source_file}</summary>
              <p>{problem.source_text}</p>
            </details>
          </>
        )}
      </div>
    </div>
  );
}

function UhuiView({ downloadElementId, problem }) {
  return (
    <div id={downloadElementId}>
      <div className="container border rounded my-3 py-3">
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["kor_type_name"]} :{" "}
            {problem.problem_id}
          </p>
        </strong>
        <strong>
          <p>{problem.timestamp}</p>
        </strong>
        <strong>
          <p>
            {problemDictionary[problem.problem_type]["ksat_question_string"]}
          </p>
        </strong>
        <div
          dangerouslySetInnerHTML={{
            __html: underlineUhuiWord(problem.paragraph.content),
          }}
        />

        <br />
        <div>
          <mark
            dangerouslySetInnerHTML={{
              __html: problem.uhui.correct_uhui
                ? "정답: " + underlineUhuiWord(problem.uhui.correct_uhui)
                : "No correct uhui provided",
            }}
          ></mark>
        </div>
      </div>
    </div>
  );
}
