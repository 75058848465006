import axios from 'axios';
import { useEffect, useState } from 'react';
import API_BASE_URL from '../../utils/API_BASE_URL';
import { problemDictionary } from '../../utils/problemDictionary';
import { SaveButtonForDaeui } from '../SaveButton';
import React from 'react';

export default function OptionOnlyInterface({ selectedProblemType, fixedParagraph }) {
    const [options, setOptions] = useState([]);
    const [logs, setLogs] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const questionString = problemDictionary[selectedProblemType]?.ksat_question_string;    

    useEffect(() => {        
        const jwt = sessionStorage.getItem('jwt');
        console.log('JWT:', jwt);
        fetchOptionsTYPE0(selectedProblemType, fixedParagraph, jwt);
    }, [selectedProblemType, fixedParagraph]);

    const handleCheckboxChange = (option) => (e) => {
        if (e.target.checked) {
            setSelectedOptions([...selectedOptions, option]);
        } else {
            setSelectedOptions(selectedOptions.filter(o => o !== option));
        }
    };

    const fetchOptionsTYPE0 = async (problem_type, paragraph_content, jwt) => {
        const data = {
            action: "build_options_component",
            problem_type: problem_type,
            paragraph_content: paragraph_content,
            jwt: jwt
        };
        try {
            const result = await axios.post(API_BASE_URL, data);
            console.log(result);
            setOptions((prevOptions) => [...prevOptions, ...result.data.options]);
            setLogs((prevLogs) => [...prevLogs, result.data.log]);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleGenerateMoreOptions = () => {
        alert("선지를 불러오는 데 3초-10초 정도 소요됩니다!"); 
        const jwt = sessionStorage.getItem('jwt');
        fetchOptionsTYPE0(selectedProblemType, fixedParagraph, jwt);
    };

    return (
        <div className='container border rounded my-3 py-2'>
            <p>{questionString}</p>
            <p>{fixedParagraph}</p>
            <p>(로딩 시각화 작업중..) 선지는 5 개 자동생성되며, '더 만들기'를 통해 더 만들 수 있습니다. 선지를 5 개 불러오는 데 약 3초-10초 정도 걸립니다.</p>
            {options.map((option, index) => (
                <Option
                    key={index}
                    option={option}
                    handleCheckboxChange={handleCheckboxChange(option)}
                    isChecked={selectedOptions.includes(option)}
                />
            ))}
            <div className='d-flex justify-content-center'>
                <button className='border rounded me-1' onClick={handleGenerateMoreOptions}>
                    선지 더 만들기
                </button>                
                <SaveButtonForDaeui
                    problem_type={selectedProblemType}
                    paragraph_content={fixedParagraph}
                    options={selectedOptions}
                />
            </div>
            <a href='https://qbot-options.streamlit.app/' target="_blank">선지 커스텀(신기능)</a>
        </div>
    );
}

function Option({ option, handleCheckboxChange, isChecked }) {
    return (
        <div>
            <input
                type="checkbox"
                onChange={handleCheckboxChange}
                checked={isChecked}
            />
            {option.is_correct ? (
                <mark>{option.content}</mark>
            ) : (
                <span>{option.content} : <small><u>{option.odap_type}</u></small></span>
            )}
        </div>
    );
}
