export function binkanContentToView(content) {
    // [빈칸]의 paragraph.content:String -> p태그로 전환 ([BLANK] -> _______________)
    return <p>{content.replace(/\[BLANK\]/g, "_______________")}</p>
}

export function hamuiContentToView(content) {
    // [함의]의 paragraph.content:String -> p태그로 전환 ([UNDERLINE_START] -> <u>, [UNDERLINE_END] -> </u>)
    const transformedContent = content.replace(/\[UNDERLINE_START\]/g, "<u>").replace(/\[UNDERLINE_END\]/g, "</u>")
    return <p dangerouslySetInnerHTML={{ __html: transformedContent }} />
}
