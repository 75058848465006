import React from 'react';
import { useNavigate } from 'react-router-dom';
import MyTickets from '../MyTickets';

export default function Home() {
    const navigate = useNavigate();

    const toBasedCreation = () => navigate('/based-creation');
    const toFileBasedCreation = () => navigate('/file-based-creation');
    const toTransformation = () => navigate('/transformation')

    return (
        <div className="container my-3">
            {sessionStorage.getItem("jwt") && <MyTickets />}             
            <div className="d-flex justify-content-center my-2 mt-3">
                <button className="w-100 mx-1 border rounded" style={{ height: '200px' }} onClick={toBasedCreation}><strong>입력기반 생성</strong></button>
                <button className="w-100 mx-1 border rounded" style={{ height: '200px' }} onClick={toFileBasedCreation}><strong>파일기반 생성</strong></button>
            </div>
            <div className='d-flex justify-content-center my-2'>
                <button className='w-100 mx-1 border rounded' style={{height: '100px'}} onClick={()=>{
                    const promptAdminKey = window.prompt("client-admin-key를 입력하세요!")
                    if (promptAdminKey === 'temp') {
                        toTransformation()
                    } else return       
                }}>
                    <strong>변형 문제 생성</strong></button>
            </div>
        </div>
    )
}