import { useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../utils/API_BASE_URL';

export default function InitInterface({ 
    inputParagraph, setInputParagraph, 
    fixedParagraph, setFixedParagraph,
    selectedProblemType,setSelectedProblemType,
    fixed, setFixed, setInterfaceType}) {

    const handleFixClick = async () => {
        if (!inputParagraph || !selectedProblemType) { alert('지문과 문제 유형을 모두 입력해주세요!'); return; }        
        if (window.confirm("선택하신 유형에 더욱 적합하게 지문을 변형하시겠습니까?")) {
            window.alert('9-15 초 정도 소요됩니다. 잠시만 기다려주세요!')
            try {
                const response = await axios.post(API_BASE_URL, {
                    action: "build_byunhyung_paragraph_component",
                    jwt: sessionStorage.getItem('jwt'),
                    paragraph_content: inputParagraph,
                    problem_type: selectedProblemType
                });                
                // setInputParagraph(response.data.paragraph.content) // 이거 대신 setFixedParagraph()
                console.log("response.data.paragraph.content", response.data.paragraph.content)
                setFixedParagraph(response.data.paragraph.content)
            } catch (error) {
                console.error("Error occurred during the API call:", error); alert("API 요청 중 오류가 발생했습니다.");
            }
        } else {
            setFixedParagraph(inputParagraph)
        }
        setFixed(true); console.log("fixed!"); alert("1 크레딧을 사용했습니다!")
    };
        

    return <div className="container border rounded py-2">
            <ParagraphTextArea inputParagraph={inputParagraph} setInputParagraph={setInputParagraph} fixed={fixed} />
            <RadioGroup setSelectedProblemType={setSelectedProblemType} fixed={fixed} />
            <button className="w-100 my-1 btn border rounded" onClick={handleFixClick} disabled={fixed}>확인</button>
            {fixed && <button className="w-100 my-1 btn border rounded" onClick={() =>{setFixed(false); setInterfaceType(null)}}>새 문제 만들기</button>}
    </div>

}

export function ParagraphTextArea({ inputParagraph, setInputParagraph, fixed }) {
    const [paragraphForRecommendation, setParagraphForRecommendation] = useState('')
    const [recommendedType, setRecommendedType] = useState('')
    
    const viewRecommendaion = async () => {
        if (!paragraphForRecommendation) { alert('지문을 입력하세요!'); return; }
        if (recommendedType) setRecommendedType('')        
        try {
            const response = await axios.post(API_BASE_URL, {
                'action' : 'recommend_type',
                'paragraph_content' : paragraphForRecommendation
            });
            if (!response.data['recommended_type']) {
                alert('[400] 에러가 발생했습니다!'); return
            }
            setRecommendedType(response.data['recommended_type'])
        } catch (error) {
            alert('[400] 에러가 발생했습니다!')
        }
        
    }  

    const handleInputChange = (e) => {
        setParagraphForRecommendation(e.target.value)
        if (!fixed) {
            setInputParagraph(e.target.value);
        }
    };

    return (
        <div>
            <small>지문을 입력해주세요</small>
            <textarea
                className="form-control"
                id="paragraphFormControl"
                rows="9"
                onChange={handleInputChange}
                disabled={fixed}
                style={{ backgroundColor: fixed ? '#e9ecef' : 'white' }}
                value={inputParagraph}
            />
            <div className="d-flex justify-content-end align-items-center mt-2">
                {recommendedType && <span className='mx-2'><strong>{recommendedType}</strong></span>}
                <button onClick={viewRecommendaion} className="btn border rounded">AI 유형 추천 보기</button>
            </div>
        </div>
    );
}

function RadioGroup({ setSelectedProblemType, fixed }) {
    const labels = [
        { label: '주제', value: 'juje' },
        { label: '제목', value: 'jemok' },
        { label: '요지', value: 'yoji' },
        { label: '목적', value: 'mokjuk' },
        { label: '주장', value: 'jujang'},
        { label: '빈칸', value: 'binkan' },
        { label: '순서', value: 'sunseo' },
        { label: '삽입', value: 'sabib' },
        { label: '함의', value: 'hamui' },
        {label : '어법', value: 'ubub'},
        {label : '어휘', value: 'uhui'}
    ];

    const [selectedValue, setSelectedValue] = useState(null);

    const handleChange = (value) => {
        if (!fixed) {
            setSelectedValue(value);
            setSelectedProblemType(value);
        }
    };

    return (
        <div className="d-flex flex-row justify-content-center mt-1">
            {labels.map((item, index) => (
                <label key={index} className="mx-2" style={{ color: fixed ? '#6c757d' : 'black' }}>
                    <input
                        type="radio"
                        name="problemType"
                        value={item.value}
                        checked={selectedValue === item.value}
                        onChange={() => handleChange(item.value)}
                        disabled={fixed}
                    />
                    {item.label}
                </label>
            ))}
        </div>
    );
}
