import React, { useContext, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../utils/API_BASE_URL';
import MaterialView from '../MaterialView';

import AppContext from '../../GLOBAL_STATE/AppContext';

export default function FileBasedCreation() {

    const {fileBasedCreationState, setFileBasedCreationState} = useContext(AppContext)

    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        if (!fileBasedCreationState['loading']) {
            setFile(event.target.files[0]);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) return;

        const confirm = window.confirm('새로운 파일기반 문제들을 만드시겠습니까?');
        if (!confirm) return;

        setFileBasedCreationState((prevState) => ({
            ...prevState,
            "loading" : true,
            "materials" : [],
            "fileName" : file.name
          }));

        const formData = new FormData();
        formData.append('file', file);
        formData.append('action', 'init_pdf');
        formData.append('jwt', sessionStorage.getItem('jwt') || '');

        try {
            const response = await axios.post(API_BASE_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("response.data['drived_binkan_problem']", response.data['drived_binkan_problem'])
            setFileBasedCreationState((prevState) => ({
                ...prevState,                
                "materials" : response.data['derived_binkan_problems']
              }));
            // sessionStorage.setItem('materials', response.data['derived_binkan_problems'])
        } catch (error) {
            console.error('파일 업로드 실패:', error);
            setError('파일 업로드에 실패했습니다. 다시 시도해주세요.');
        } finally {
            setFileBasedCreationState((prevState) => ({
                ...prevState,                
                'loading' : false
              }));
        }
    };

    return (    
        <div className="container">
            {/* <div>{JSON.stringify(fileBasedCreationState)}</div> [디버그용 코드] */}
                <h4 className='mt-3'>파일 기반 생성</h4>
                {error && <div className="alert alert-danger">{error}</div>}
                <div>파일을 처리하는 동안 창을 새로고침(f5)하지 않도록 주의하세요!</div>
                <form onSubmit={handleSubmit} className='container my-2'>
                    {fileBasedCreationState.fileName && <div>작업중인 파일 : {fileBasedCreationState.fileName}</div>}
                    <div>새 파일을 등록하거나 자료들을 확인해보세요</div>
                    <div className="d-flex justify-content-center">
                        <input type="file" onChange={handleFileChange} disabled={fileBasedCreationState.loading} />
                        <button type="submit" disabled={fileBasedCreationState.loading}>파일 전송</button>
                    </div>
                </form>
                {fileBasedCreationState.fileName && fileBasedCreationState.loading && <div>AI가 {fileBasedCreationState.fileName}를 처리하고 있습니다.. </div>}
                {fileBasedCreationState.materials && fileBasedCreationState.materials.map((material) => (
                    <MaterialView key={material.problem_id} material={material} />
                ))}
        </div>        
    );
}
