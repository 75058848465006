import { useEffect, useState, useCallback } from "react";
import axios from "axios";

import CheckBoxes from "../CheckBoxes";
import MaterialView from '../MaterialView';
import API_BASE_URL from "../../utils/API_BASE_URL";
import filterMaterialsByQuery from "../../utils/filterMaterialsByQuery";
import MyTickets from "../MyTickets";

export default function MyMaterials() {
    const [materials, setMaterials] = useState([]); // 유저가 만든 모든 자료들
    const [viewMaterials, setViewMaterials] = useState([]); // 유저에게 디스플레이되는 자료들

    const [selectedProblemTypes, setSelectedProblemTypes] = useState([]);
    const [timelineSortingOrder, setTimelineSortingOrder] = useState('최근순');
    const [queryString, setQueryString] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const data = {
                "action": "get_my_materials",
                "jwt": sessionStorage.getItem("jwt")
            };
            try {
                const response = await axios.post(API_BASE_URL, data);
                console.log(response.data);
                const fetchedMaterials = response.data.data.problems || [];
                setMaterials(fetchedMaterials);
                
                // 자료를 불러온 후 '최근순' 정렬을 적용합니다.
                fetchedMaterials.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setViewMaterials(fetchedMaterials);
            } catch (error) {
                console.error("Error fetching materials:", error);
            }
        };
        fetchData();
    }, []);

    const filterAndSortMaterials = useCallback(() => {
        let filteredMaterials = materials;
        if (selectedProblemTypes.length > 0) {
            filteredMaterials = filteredMaterials.filter((material) => {
                return selectedProblemTypes.includes(material.problem_type);
            });
        }
        if (timelineSortingOrder === '최근순') {
            filteredMaterials.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        } else {
            filteredMaterials.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        }
        if (queryString) {
            if (!filteredMaterials) return;
            filteredMaterials = filterMaterialsByQuery(filteredMaterials, queryString);
        }

        setViewMaterials(filteredMaterials);
    }, [materials, selectedProblemTypes, timelineSortingOrder, queryString]);

    useEffect(() => {
        filterAndSortMaterials();
    }, [materials, selectedProblemTypes, timelineSortingOrder, queryString, filterAndSortMaterials]);

    useEffect(() => console.log(queryString), [queryString]);

    return (
        <div className="container">
            {sessionStorage.getItem('jwt') && <MyTickets />}  
            <hr />
            <FilterGroup selectedProblemTypes={selectedProblemTypes} setSelectedProblemTypes={setSelectedProblemTypes} setQueryString={setQueryString} />  
            {viewMaterials.length > 0 ? (
                viewMaterials.map((material) => {
                    return <MaterialView key={material.problem_id} material={material} />;
                })
            ) : (
                <p>No materials found.</p>
            )}
        </div>
    );
}

function  FilterGroup({selectedProblemTypes, setSelectedProblemTypes, setQueryString}) {
    return <>
        <div className="d-flex flex-column align-items-center border rounded mx-1 my-3 py-1">
            <CheckBoxes selectedProblemTypes={selectedProblemTypes} 
                    setSelectedProblemTypes={setSelectedProblemTypes}/>
            <QueryInput setQueryString={setQueryString}/>
        </div>
    </>
}

function QueryInput({ setQueryString }) {
    return (
        <div className="d-flex">            
            <input type="text" placeholder="검색어" onChange={(e) => {
                setQueryString(e.target.value);
            }} />            
        </div>
    );
}
